<template>
  <div v-if="templateOptions" class="fd-px-3 fd-py-5">
    <div class="fd-w-full fd-border-b fd-border-theme-5 fd-px-6 fd-py-5 fd-flex fd-items-center fd-justify-between">
      <label for="name" class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">
        <span>Template Name</span>
        <span class="fd-block fd-text-lg fd-text-red-500 fd-font-normal fd--mt-2">*</span>
      </label>
      <div class="fd-w-2/3">
        <b-form-input
          id="name"
          placeholder="Template Name"
          v-model="form.name"
        />
      </div>
    </div>

    <div class="fd-w-full fd-border-b fd-border-theme-5 fd-px-6 fd-py-5 fd-flex fd-items-center fd-justify-between">
      <label class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">
        <span>Template Type</span>
        <span class="fd-block fd-text-lg fd-text-red-500 fd-font-normal fd--mt-2">*</span>
      </label>
      <div class="fd-w-2/3">
        <multiselect
          select-label=""
          deselect-label=""
          label="name"
          track-by="id"
          v-model="form.template_type_id"
          :options="templateOptions.template_type"
        />
      </div>
    </div>

    <div class="fd-w-full fd-border-b fd-border-theme-5 fd-px-6 fd-py-5 fd-flex fd-items-center fd-justify-between">
      <label class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">
        <span>Property Type</span>
        <span class="fd-block fd-text-lg fd-text-red-500 fd-font-normal fd--mt-2">*</span>
      </label>
      <div class="fd-w-2/3">
        <multiselect
          select-label=""
          deselect-label=""
          v-model="form.property_type"
          :options="templateOptions.property_type"
        />
      </div>
    </div>

    <div class="fd-w-full fd-border-b fd-border-theme-5 fd-px-6 fd-py-5 fd-flex fd-items-center fd-justify-between">
      <label class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">
        <span>Living Room</span>
        <span class="fd-block fd-text-lg fd-text-red-500 fd-font-normal fd--mt-2">*</span>
      </label>
      <div class="fd-w-2/3">
        <multiselect
          select-label=""
          deselect-label=""
          v-model="form.living_room"
          :options="templateOptions.living_room"
        />
      </div>
    </div>

    <div class="fd-w-full fd-border-b fd-border-theme-5 fd-px-6 fd-py-5 fd-flex fd-items-center fd-justify-between">
      <label class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">
        <span>Bed Room</span>
        <span class="fd-block fd-text-lg fd-text-red-500 fd-font-normal fd--mt-2">*</span>
      </label>
      <div class="fd-w-2/3">
        <multiselect
          select-label=""
          deselect-label=""
          v-model="form.bed_room"
          :options="templateOptions.bedroom"
        />
      </div>
    </div>

    <div class="fd-w-full fd-border-b fd-border-theme-5 fd-px-6 fd-py-5 fd-flex fd-items-center fd-justify-between">
      <label class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">
        <span>Bathroom</span>
        <span class="fd-block fd-text-lg fd-text-red-500 fd-font-normal fd--mt-2">*</span>
      </label>
      <div class="fd-w-2/3">
        <multiselect
          select-label=""
          deselect-label=""
          v-model="form.bath_room"
          :options="templateOptions.bathroom"
        />
      </div>
    </div>

    <div class="fd-w-full fd-border-b fd-border-theme-5 fd-px-6 fd-py-5 fd-flex fd-items-center fd-justify-between">
      <label for="net" class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">
        <span>Net</span>
        <span class="fd-block fd-text-lg fd-text-red-500 fd-font-normal fd--mt-2">*</span>
      </label>
      <div class="fd-w-2/3">
        <b-form-input
          id="net"
          placeholder="Net"
          v-model="form.net"
        />
      </div>
    </div>

    <div class="fd-w-full fd-border-b fd-border-theme-5 fd-px-6 fd-py-5 fd-flex fd-items-center fd-justify-between">
      <label for="gross" class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">
        <span>Gross</span>
        <span class="fd-block fd-text-lg fd-text-red-500 fd-font-normal fd--mt-2">*</span>
      </label>
      <div class="fd-w-2/3">
        <b-form-input
          id="gross"
          placeholder="Gross"
          v-model="form.gross"
        />
      </div>
    </div>

    <div class="fd-w-full fd-px-6 fd-py-5 fd-flex fd-items-center fd-justify-between">
      <label class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">
        <span>Orientation</span>
      </label>
      <div class="fd-w-2/3">
        <multiselect
          select-label=""
          deselect-label=""
          v-model="form.orientation"
          :options="templateOptions.orientations"
        />
      </div>
    </div>

    <div class="fd-w-full fd-flex fd-items-start fd-justify-end fd-mt-8">
      <b-button @click="next" variant="primary" class="fd-mr-6" :disabled="!validateForm">
        <span class="fd-px-10">Next</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { BFormGroup, BFormInput, BButton } from 'bootstrap-vue'
import Multiselect from 'vue-multiselect'
import 'vue-search-select/dist/VueSearchSelect.css'
import {mapGetters} from "vuex";

export default {
  name: "MainInfo",
  components: {BFormGroup, BFormInput, BButton, Multiselect},
  data() {
    return {
      form: {
        name: '',
        template_type_id: '',
        property_type: '',
        living_room: '',
        bed_room: '',
        bath_room: '',
        net: '',
        gross: '',
        orientation: ''
      }
    }
  },
  created() {
    this.$store.dispatch('project/getTemplateOptions')
    if (!this.$route.params.templateId) return
    this.$store.dispatch('project/getMainInfoTemplate', this.$route.params.templateId).then(() => {
      this.dataPreparation()
    })
  },
  computed: {
    ...mapGetters({
      'templateOptions': 'project/templateOptions'
    }),
    validateForm() {
      return !!(this.form.name !== '' &&
        this.form.template_type_id !== '' &&
        this.form.property_type !== '' &&
        this.form.living_room !== '' &&
        this.form.bed_room !== '' &&
        this.form.bath_room !== '' &&
        this.form.net !== '' &&
        this.form.gross !== '')
    }
  },
  methods: {
    dataPreparation() {
      const mainInfo = this.$store.getters["project/mainInfoTemplate"]
      this.form.name = mainInfo.name
      this.form.property_type = mainInfo.property_type
      this.form.living_room = mainInfo.living_room
      this.form.bed_room = mainInfo.bed_room
      this.form.bath_room = mainInfo.bath_room
      this.form.net = mainInfo.net
      this.form.gross = mainInfo.grass
      this.form.orientation = mainInfo.orientation
      this.templateOptions.template_type.forEach(item => {
        if (mainInfo.template_type.id === item.id) {
          this.form.template_type_id = item
        }
      })

    },
    next() {
      if (!this.validateForm) return

      let form = this.form
      form.template_type_id = form.template_type_id.id

      if (this.$route.params.templateId) {
        this.$store.dispatch('project/updateTemplateDetails', {
          templateId: this.$route.params.templateId,
          data: form
        }).then(() => {
          this.goTo(this.$route.params.templateId)
        })
        return;
      }

      this.$store.dispatch('project/createNewTemplate', {
        id: this.$route.params.id,
        data: form
      }).then(response => {
        this.goTo(response.data.result.id)
      })
    },

    goTo(templateId) {
      this.$router.push({
        name: 'projects.templates.media',
        params: {
          id: this.$route.params.id,
          templateId: templateId
        }
      })
    }
  }
}
</script>

<style scoped>

</style>